// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $(".home-slider"),
  gallery: $(".gallery"),
  galleryMobile: $(".galleryMobile"),
  textGallery: $(".textGallery"),
  galleryWithText: $(".galleryWithText"),
  textGalleryMobile: $(".textGalleryMobile"),
  galleryWithTextMobile: $(".galleryWithTextMobile"),
  personGallery: $(".personsGallery")
};

sliders.textGalleryMobile.slick({
  dots: false,
  arrows: false,
  autoplay: false,
  asNavFor: sliders.galleryWithTextMobile
});

sliders.galleryWithTextMobile.slick({
  dots: true,
  prevArrow: $(".prevArrowMobile"),
  nextArrow: $(".nextArrowMobile"),
  appendDots: $(".arrowsMobile .elementor-widget-wrap"),
  autoplay: true,
  asNavFor: sliders.textGalleryMobile ? sliders.textGalleryMobile : false
});

sliders.home.slick({
  dots: false,
  autoplay: false
});

sliders.textGallery.slick({
  dots: false,
  arrows: false,
  autoplay: false,
  asNavFor: $(".galleryWithText")
});

sliders.galleryWithText.slick({
  dots: true,
  prevArrow: $(".prevArrow"),
  nextArrow: $(".nextArrow"),
  appendDots: $(".arrows .elementor-widget-wrap"),
  autoplay: true,
  asNavFor: $(".textGallery") ? $(".textGallery") : false
});

sliders.gallery.slick({
  dots: true,
  prevArrow: $(".prevArrow"),
  nextArrow: $(".nextArrow"),
  appendDots: $(".arrows .elementor-widget-wrap"),
  autoplay: true
});

sliders.galleryMobile.slick({
  dots: true,
  prevArrow: $(".prevArrowMobile"),
  nextArrow: $(".nextArrowMobile"),
  appendDots: $(".arrowsMobile .elementor-widget-wrap"),
  autoplay: true
});

sliders.personGallery.slick({
  dots: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  prevArrow: $(".prevArrow"),
  nextArrow: $(".nextArrow"),
  appendDots: $(".arrows .elementor-widget-wrap"),
  autoplay: false,
  responsive: [
    {
      breakpoint: 1370,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 1587,
      settings: {
        slidesToShow: 2
      }
    }
  ]
});
