import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};

window.addEventListener("click", (e) => {
  let els = [];
  let a = e.target;
  while (a) {
    els.unshift(a);
    a = a.parentNode;
  }
  els.forEach(forEach)
  function forEach(item, index) {
    if(item && item.classList) {
      const classList = item.classList;
      if(classList.contains("circle")) {
        window.location = `#${item.dataset.href}`;
      }
      if(classList.contains("realizationTerm")) {
        realizationTerm(item);
      }
      if(classList.contains("countryTerm")) {
        countryTerm(item);
      }
    }
  }

})

function findGetParameter(parameterName) {
  var result = null,
      tmp = [];
  location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
  return result;
}



let realizationTermParams = [];
let countryTermsParams = [];

window.onload = function() {
  const realizationTermParamsString = findGetParameter("realizationTermParams");
  const countryTermsParamsString = findGetParameter("countryTermsParams");
  realizationTermParams = realizationTermParamsString ? realizationTermParamsString.split(",").map((e) => {return e}) : [];
  countryTermsParams = countryTermsParamsString ? countryTermsParamsString.split(",").map((e) => {return e}) : [];
  setActiveClass();
};

function setActiveClass () {
    realizationTermParams.forEach(setClass);
    countryTermsParams.forEach(setClass);
}

function setClass(element, index, array) {
  document.querySelectorAll(`[data-slug='${element}']`)[0].classList.add("activeElement");
}


function countryTerm(e) {
  const slug = e.dataset.slug;
  if(countryTermsParams.includes(slug)) {
    countryTermsParams = countryTermsParams.filter(function(value, index, arr){
      if(value !== slug) {
        return value;
      }
    });
  } else {
    countryTermsParams.push(slug);
  }
  setParams();
}

function realizationTerm(e) {
  const slug = e.dataset.slug;
  if(realizationTermParams.includes(slug)) {
    realizationTermParams = realizationTermParams.filter(function(value, index, arr){
      if(value !== slug) {
        return value;
      }
    });
  } else {
    realizationTermParams.push(slug);
  }
  setParams();
}

function setParams() {
    console.log(countryTermsParams);
    const string1 = realizationTermParams.length > 0 ? `?realizationTermParams=${realizationTermParams.join()}` : "?";
    const string2 = countryTermsParams.length > 0 ? `&countryTermsParams=${countryTermsParams.join()}` : "";
  window.location.href = string1 + string2;
}

const circleButton = document.getElementsByClassName("circleButton");

for(let i = 0; i<= circleButton.length; i++) {
  const item = circleButton[i];
  if(item) {
    const img = item.querySelectorAll(".elementor-widget-container svg");
    console.log(img);
    img[0].onclick = () => {
      const classList = item.classList;
      console.log(classList[3]);
      const modal = document.getElementsByClassName(classList[3]);
      let correctModal;
      for(let i = 0; i <= modal.length; i++) {
        if(modal[i] && modal[i].classList.contains("modal")) {
          correctModal = modal[i];
        }
      }
      const activeModal = document.getElementsByClassName("modalActive")
      if(correctModal.classList.contains("modalActive")) {
        activeModal[0].classList.remove("modalActive");
      } else {
        if(activeModal.length > 0) {
          activeModal[0].classList.remove("modalActive");
        }
        item.appendChild(correctModal);
        correctModal.classList.add("modalActive");
      }
    }
  }
}

const closeMenus = document.getElementById("closeMenu");
const menuButton = document.getElementById("menuButton");
const menu = document.getElementById("menu");

menu.onclick = () => {
  event.stopPropagation();
}

menuButton.onclick = () => {
  event.stopPropagation();
  if(menu.classList.contains("menuActive")) {
    menu.classList.remove("menuActive");
    menuButton.classList.remove("menuButtonActive");
  } else {
    menu.classList.add("menuActive");
    menuButton.classList.add("menuButtonActive");
  }

}

closeMenus.onclick = () => {

}

window.onclick = () => {
  menu.classList.remove("menuActive");
  menuButton.classList.remove("menuButtonActive");
}

const linkButton = document.getElementById("galleryLink");

linkButton.onclick = () => {
  const link = document.querySelector(".slick-current .galleryLink");
  console.log(link);
  window.location.href = link.value;
}

const linkButtonGallery = document.getElementById("galleryLinkMobile");

linkButtonGallery.onclick = () => {
  const link = document.querySelector(".slick-current .galleryLink");
  console.log(link);
  window.location.href = link.value;
}

const pierwszaSekcjaButton = document.getElementById("pierwszaSekcjaButton");

pierwszaSekcjaButton.onclick = () => {
  window.location.href = "#pierwszaSekcja";
}
